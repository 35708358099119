<script>
  import SickButton from "./components/styles/SickButton.svelte";
</script>

<style>
  main {
    text-align: center;
    padding: 2em;
    max-width: 350px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    color: #ff3e00;
    text-transform: uppercase;
    font-size: 8em;
    font-weight: 100;
    margin-top: 10vh;
    margin-bottom: 10vh;
  }

  .buttons {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 25px;
    align-items: center;
  }

  .emojis {
    margin-bottom: 12vh;
    word-spacing: 50px;
    font-size: 70px;
  }

  @media screen and (max-width: 600px) {
    h1 {
      margin-top: 5vw;
      font-size: 4.5em;
    }

    .emojis {
      word-spacing: 45px;
      font-size: 50px;
    }

    .buttons {
      width: 67%;
      grid-auto-flow: row;
    }
  }
</style>

<main>

  <h1>PissLizard</h1>
  <div class="emojis">💦 🚽 🦎</div>
  <div class="buttons">
    <SickButton text={'Shop'} />
    <SickButton text={'About'} />
  </div>
</main>
