<script>
  export let text;

  const handleClick = () => {
    const oldText = text;
    text = "Coming Soon...";

    setTimeout(() => {
      text = oldText;
    }, 2000);
  };
</script>

<style>
  button {
    background: #ff3e00;
    color: white;
    white-space: nowrap;
    font-weight: 400;
    border: 5px solid #ff3e00;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 2rem;
    padding: 0.8rem 1.5rem;
    transform: skew(-5deg);
    display: inline-block;
    transition: all 0.5s;
  }

  button:hover {
    background: white;
    color: #111;
  }

  @media screen and (max-width: 600px) {
    button {
      font-size: 1.5rem;
    }
  }
</style>

<button on:click={handleClick}>{text}</button>
